<template>
  <v-app-bar
    id="core-app-bar"
    absolute
    app
    color="transparent"
    flat
    height="88"
  >
    <v-toolbar-title
      class="tertiary--text font-weight-light align-self-center mx-5"
    >
      <v-btn
        v-if="responsive"
        color="primary"
        icon
        @click.stop="onClick"
      >
        <v-icon
          color=""
          large
        >
          mdi-menu
        </v-icon>
      </v-btn>
      <h2 class="float-right primary--text title-compact">
        {{ title }}
      </h2>
    </v-toolbar-title>

    <v-spacer/>

    <v-toolbar-items>
      <v-row align="center">
        <v-btn
          class="mr-5"
          icon
          to="/alertes"
        >
          <v-icon
            color="primary"
            large
          >
            mdi-bell-outline
          </v-icon>
        </v-btn>
        <v-btn
          class="mr-8"
          to="/profile"
          icon
        >
          <v-icon
            color="primary"
            large
          >
            mdi-account-outline
          </v-icon>
        </v-btn>
      </v-row>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
  import UploadFileBtn from '@/components/bytel/UploadFileBtn'
  // Utilities
  import { mapMutations } from 'vuex'
  import Constant from '@/constant'

  export default {
    components: {
      UploadFileBtn,
    },
    data: () => ({
      notifications: [
        'Mike, John responded to your email',
        'You have 5 new tasks',
        'You\'re now a friend with Andrew',
        'Another Notification',
        'Another One',
      ],
      title: null,
      responsive: false,
      DateTime: new Date().toUTCString(),
      group: '',
      admins: [...Constant.admin_groups()],
    }),
    watch: {
      $route (val) {
        this.title = val.name
      },
    },
    mounted () {
      this.group = this.$store.getters.group
      const self = this
      setInterval(() => {
        self.DateTime = new Date().toUTCString()
      }, 1000)
      this.onResponsiveInverted()
      window.addEventListener('resize', this.onResponsiveInverted)
      this.title = this.$route.name
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.onResponsiveInverted)
    },
    methods: {
      ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
      onClick () {
        this.setDrawer(!this.$store.state.app.drawer)
      },
      onResponsiveInverted () {
        if (window.innerWidth < 1263) {
          this.responsive = true
        } else {
          this.responsive = false
        }
      },
    },
    computed: {
      isAdmin () {
        return this.admins.includes(this.group)
      },
    },
  }
</script>

<style>
/* Fix coming in v2.0.8 */
#core-app-bar {
  width: auto;
}

#core-app-bar a {
  text-decoration: none;
}

@media screen and (max-width: 640px) {
  .title-compact {
    font-size: 1em;
    padding: 4px;
  }
}

</style>
